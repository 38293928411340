<template>
  <div class="apply">
    <center-title title="申请售后">
      <!-- <div class="title-right white row-center cursor xs">售后政策</div> -->
    </center-title>
    <div class="pro-list lighter nr">
      <div class="pro-item row th bg-center">
        <div class="item flex3 pro">商品名称</div>
        <div class="item flex2">商品规格</div>
        <div class="item flex2">购买数量</div>
        <div class="item flex2">支付总价</div>
      </div>
      <div class="pro-item row xs normal" v-for="(item, index) in orderList" :key="index">
        <div class="item flex3 pro row">
          <el-image class="pro-img" :src="item.image" fit="contain"></el-image>
          <router-link :to="`/prodetail?id=${item.goods_id}`" class="line2">{{ item.goods_name }}
          </router-link>
        </div>
        <div class="item flex2 row-center">{{ item.spec_value }}</div>
        <div class="item flex2 row-center">{{ item.goods_num }}</div>
        <div class="item flex2 row-center">
          <make-priceformat :price="item.total_pay_price" :subscriptSize="16" :firstSize="16" :secondSize="16">
          </make-priceformat>
        </div>
      </div>
    </div>
    <div class="apply-tips xs mb20">
      <span class="price">*温磐提示</span>:本次售后服务将由大雨网为您提供
    </div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="152px" class="ruleForm lighter">
      <el-form-item label="服务类型：" prop="type">
        <el-radio-group v-model="ruleForm.type" @change="changeType">
          <el-radio label="0">仅退款</el-radio>
          <el-radio label="1" v-if="!allType">退货</el-radio>
          <el-radio label="2" v-if="!allType">换货</el-radio>
          <el-radio label="3" v-if="!allType">维修</el-radio>
          <el-radio label="4" v-if="!allType">补寄</el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- <el-form-item label="提交数量：" prop="num" v-if="(ruleForm.type == '0') && !allType">
        <div class="number column xs">
          <el-input-number v-model="ruleForm.goods_num" :min="1" :max="1" class="mb10">
          </el-input-number>
          您最多可提交数量为1个
        </div>
      </el-form-item> -->
      <el-form-item label="服务支持：" prop="require" :required="true"
        v-if="ruleForm.type == '1' || ruleForm.type == '0' && !allType">
        <div class="support">
          破损包退换 <span class="price">•</span> 30天价保
          <span class="price">•</span> 闪电退款
        </div>
      </el-form-item>

      <el-form-item label="退款方式：" prop="way" v-if="ruleForm.type == '1' || ruleForm.type == '0'">
        <el-radio-group value="原支付方式返回">
          <el-radio label="原支付方式返回"></el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- <el-form-item
        label="退货商品："
        :required="true"
        v-if="ruleForm.type == '退货'"
      >
        <div class="row warn mb10">
          同订单商品可以一起申请退货
          <div class="add row ml20 normal">
            <img src="@/assets/icon/icon-add1.png" alt="" class="mr5" />添加
          </div>
        </div>
        <div class="sales-list row bg-center">
          <div class="sales-item row">
            <el-image src="" fit="cover" class="mr20"></el-image>
            <div class="item-content row">
              <div class="title line2 mr30">
                打印机家用小型L3153彩色多 功能一体机...
              </div>
              <div class="item-num">x1</div>
              <div class="number column xs">
                <el-input-number
                  v-model="ruleForm.num"
                  :min="1"
                  :max="10"
                  class="mb10"
                ></el-input-number>
                您最多可提交数量为1个
              </div>
            </div>
          </div>
        </div>
      </el-form-item> -->
      <el-form-item label="提交原因：" prop="reason" v-if="ruleForm.type == '0'">
        <el-select v-model="ruleForm.reason" placeholder="请选择">
          <el-option label="多拍、错拍、不想要" value="多拍、错拍、不想要"> </el-option>
          <el-option label="快递一直未送达" value="快递一直未送达"> </el-option>
          <el-option label="未按承诺时间发货" value="未按承诺时间发货"> </el-option>
          <el-option label="快递无跟踪记录" value="快递无跟踪记录"> </el-option>
          <el-option label="空包裹" value="空包裹"> </el-option>
          <el-option label="缺货" value="缺货"> </el-option>
          <el-option label="其他" value="其他(描述中写明原因)"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="提交原因：" prop="reason" v-if="ruleForm.type == '1'">
        <el-select v-model="ruleForm.reason" placeholder="请选择">
          <el-option v-for="(item, index) in options" :key="index" :label="item" :value="item">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="提交原因：" prop="reason" v-if="ruleForm.type == '2'">
        <el-select v-model="ruleForm.reason" placeholder="请选择">
          <el-option label="发错货" value="发错货"> </el-option>
          <el-option label="颜色/尺寸/参数不符" value="颜色/尺寸/参数不符"></el-option>
          <el-option label="少件/漏发" value="少件/漏发"></el-option>
          <el-option label="商品瑕疵" value="商品瑕疵"></el-option>
          <el-option label="其他(描述中写明原因)" value="其他(描述中写明原因)"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="提交原因：" prop="reason" v-if="ruleForm.type == '3'">
        <el-select v-model="ruleForm.reason" placeholder="请选择">
          <el-option label="商品故障" value="商品故障"> </el-option>
          <el-option label="其他(描述中写明原因)" value="其他(描述中写明原因)"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="提交原因：" prop="reason" v-if="ruleForm.type == '4'">
        <el-select v-model="ruleForm.reason" placeholder="请选择">
          <el-option label="一直未收到货" value="一直未收到货"> </el-option>
          <el-option label="商家少发/漏发" value="商家少发/漏发"> </el-option>
          <el-option label="其他(描述中写明原因)" value="其他(描述中写明原因)"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item
        label="商品状态："
        prop="state"
        v-if="ruleForm.type == '退货'"
      >
        <el-radio-group v-model="ruleForm.state">
          <el-radio label="已拆封"></el-radio>
          <el-radio label="未拆封"></el-radio>
        </el-radio-group>
        <p>大雨会在收货时再次确认商品状态</p>
      </el-form-item> -->
      <el-form-item label="问题描述：" prop="remark">
        <el-input type="textarea" v-model="ruleForm.remark" rows="6" maxlength="120" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="图片信息：" :class="{ max: ruleForm.img.length == 3 }">
        <el-upload :action="`${download}/file/formimage`" :headers="{ token: $store.getters.userInfo.token }" :limit="3"
          list-type="picture-card" :on-remove="handleRemove" :before-upload="beforeAvatarUpload" :on-success="
            (data) => {
              ruleForm.img.push(data.data.uri);
            }
          ">
          <i slot="default" class="el-icon-plus"></i>
          <div slot="file" slot-scope="{ file }">
            <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <div class="tips-box">
          <p class="normal nr mb5">为了帮助您更好的解决问题，请上传图片</p>
          <p class="xs">
            最多可，上传3张图片,每张图片大小不超过1M.支持jpg、png格式文件
          </p>
        </div>
      </el-form-item>
    </el-form>
    <!-- <div class="sure-info lighter mb20">
      <div class="info-title">确认信息<span class="warn">【修改】</span></div>
      <div class="info-list">
        <div class="info-item row">
          <div class="item-title">申请凭据：</div>
          <div class="item-content">有发票</div>
        </div>
        <div class="info-item row">
          <div class="item-title"><span class="price">*</span>返回方式：</div>
          <div class="item-content">
            <span class="price">自行寄出</span>将在13天内为您上门取回商品
          </div>
        </div>
        <div class="info-item row">
          <div class="item-title"><span class="price">*</span>取货信息：</div>
          <div class="item-content">
            山东省淄博市张店区城区花园小区20号楼2单元101 王小米收158-****-9088
          </div>
        </div>
      </div>
    </div> -->

    <div class="row-end mb20">
      <div class="mr30" v-if="(ruleForm.type == '1' || ruleForm.type == '0')">
        退款金额：
        <make-priceformat :price="saleGoods.total_pay_price" :subscriptSize="16" :firstSize="16" :secondSize="16">
        </make-priceformat>
      </div>
      <button type="primary" class="white md bg-primary sure-btn" @click="submitForm('ruleForm')">
        确认提交
      </button>
    </div>
  </div>
</template>
<script>
import { vxRule } from "@/utils/validator";
import CenterTitle from "../components/CenterTitle";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
  },
  data() {
    return {
      ruleForm: {
        require: 1,
        type: "0", //0仅退款 1退款退货 2换货 3维修 4补寄
        goods_num: 1,
        way: 1,
        reason: "",
        state: "",
        remark: "",
        img: [],
        refund_type: null,
      },
      allType: false,
      options: [
        '7天无理由退换货',
        '大小尺寸与商品描述不符',
        '颜色/图案/款式不符',
        '做工粗糙/有瑕疵',
        '质量问题',
        '卖家发错货',
        '少件（含缺少配件）',
        '不喜欢/不想要',
        '快递/物流一直未送到',
        '空包裹',
        '快递/物流无跟踪记录',
        '货物破损已拒签',
        '其他',
      ],
      num: 1,
      download: process.env.VUE_APP_DOWNLOAD_URL,
      rules: {
        type: vxRule(true, null, "change", "请选择服务类型"),
        goods_num: vxRule(true, null, "blur", "请填写数量"),
        way: vxRule(true, null, "change", "请选择退款方式"),
        reason: vxRule(true, null, "change", "请选择提交原因"),
        state: vxRule(true, null, "change", "请选择商品状态"),
        remark: vxRule(true, null, "blur", "请填写问题描述"),
      },
      dialogImageUrl: "",
      dialogVisible: false,
      item_id: "",
      order_id: "",
      saleGoods: {},
      orderList: []
    };
  },
  computed: {},
  watch: {},
  methods: {
    changeType(){
      this.ruleForm.reason = ''
      this.$refs['ruleForm'].clearValidate()
    },
    /**
    * @description 获取订单详情
    * @return void
    */
    getOrderDetail() {
      this.$api
        .getOrderDetailApi({
          id: this.order_id,
        })
        .then((res) => {
          this.orderList = res.data.order_goods
          this.saleGoods.total_pay_price = res.data.total_amount
        });
    },
    handleRemove() { },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 1;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG或PNG 格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 1MB!");
        return false;
      }
      return isJPG && isLt2M;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let { type, img } = this.ruleForm;
          this.ruleForm.refund_type = type
          let api = this.allType ? this.$api.addAfterSaleOrderApi : this.$api.addAfterSaleApi

          api({
            ...this.ruleForm,
            img: img.join(","),
            item_id: this.item_id,
            order_id: this.order_id,
          })
            .then((res) => {
              if (res.code == 1) {
                this.$message({
                  message: res.msg,
                  type: "success",
                  onClose: () => {
                    this.$router.replace({ path: "/saleslist" });
                  },
                });
              }
            });
        } else {
          return false;
        }
      });
    },
    getAfterSaleGoodsInfo() {
      this.$api
        .getAfterSaleGoodsInfoApi({
          order_id: this.order_id,
          item_id: this.item_id,
        })
        .then((res) => {
          this.saleGoods = res.data.goods;
          this.orderList = [res.data.goods]
        });
    },
  },
  created() {
    let { item_id, order_id, type } = this.$route.query;
    this.item_id = item_id;
    this.order_id = order_id;
    if (type && type == 'all') {
      this.allType = true
      this.getOrderDetail();
    } else {
      this.getAfterSaleGoodsInfo();
    }
  },
  mounted() { },
  beforeDestroy() { },
};
</script>
<style lang='scss' scoped>
.apply {
  .center-title {
    padding-bottom: 10px;
    border-color: $-color-border;
  }

  .title-right {
    width: 80px;
    height: 26px;
    background-color: $-color-primary;
    border-radius: 3px;
    line-height: 26px;
  }

  .pro-list {
    text-align: center;
    margin-bottom: 15px;

    .pro-item {
      align-items: inherit;

      &.th {
        height: 30px;
        line-height: 30px;
      }

      &:not(.th) {
        .item {
          padding: 30px 25px;
          border-left: $-solid-border;
          border-bottom: $-solid-border;

          &:last-child {
            border-right: $-solid-border;
          }
        }
      }

      .item {
        padding: 0 25px;

        &.pro {
          text-align: left;

          .pro-img {
            width: 54px;
            height: 41px;
            margin-right: 18px;
            flex-shrink: 0;
          }
        }
      }
    }
  }

  ::v-deep .el-form {
    border: $-solid-border;
    padding: 38px 38px 50px 0;
    margin-bottom: 15px;

    .number {
      line-height: 1;
    }

    .el-input-number {
      width: 76px;
      line-height: 25px;
      display: flex;
      align-items: row;

      .el-input-number__decrease,
      .el-input-number__increase {
        top: 0;
        left: 0;
        width: 14px;
        border: $-solid-border;
        height: 25px;
        line-height: 25px;
        font-size: 12px;
        border-radius: 0;
        background: linear-gradient(0deg, #fafafa, #ededed);
      }

      .el-input-number__increase {
        left: auto;
        right: 0;
      }

      .el-input__inner {
        padding: 0;
        margin: 0 16px;
        width: 44px;
        height: 25px;
        line-height: 25px;
        font-size: 14px;
        border-radius: 0;
        background: linear-gradient(0deg, #fafafa, #ededed);
      }
    }

    .el-radio {
      margin-right: 10px;

      .el-radio__input {
        &.is-checked {
          &+.el-radio__label {
            border-color: $-color-price;
            color: $-color-lighter;
          }
        }

        .el-radio__inner {
          display: none;
        }

        &+.el-radio__label {
          height: 30px;
          background: #ffffff;
          border: $-solid-border;
          padding: 7px 25px;
          color: $-color-lighter;
          border-width: 2px;
        }
      }
    }

    .el-form-item {
      &.max {
        .el-upload {
          display: none;
        }
      }
    }

    .el-input__inner,
    .el-textarea__inner,
    .el-upload--picture-card,
    .el-upload-list__item {
      resize: none;
      border-radius: 2px;
    }

    .el-textarea {
      width: 455px;

      .el-input__count {
        color: $-color-warn;
        font-size: 14px;
        bottom: -40px;
        background-color: transparent;
        right: 0;
      }
    }

    .el-upload-list--picture-card {
      display: inline-flex;

      .el-upload-list__item {
        width: 55px;
        height: 55px;
        margin-bottom: 0;
      }
    }

    .el-upload--picture-card {
      width: 55px;
      height: 55px;
      line-height: 55px;
      position: relative;

      &::before {
        content: "上传图片";
        position: absolute;
        right: -80px;
        white-space: nowrap;
      }

      i {
        font-size: 16px;
      }
    }

    .tips-box {
      line-height: 1.5;
    }
  }

  .sales-list {
    .sales-item {
      width: 100%;
      border-bottom: $-solid-border;
      border-width: 2px;
      padding: 15px 30px;
      line-height: 1.5;

      .el-image {
        width: 64px;
        height: 64px;
        flex-shrink: 0;
      }

      .item-content {
        padding-top: 10px;
        align-items: flex-start;

        .title {
          width: 200px;
        }

        .item-num {
          margin-right: 40px;
        }
      }
    }
  }

  .sure-info {
    border: $-solid-border;
    padding: 20px;

    .info-title {
      margin-bottom: 35px;
    }

    .info-list {
      margin-left: 35px;
      line-height: 1.5;

      .info-item {
        margin-bottom: 20px;

        .item-title {
          width: 94px;
        }
      }
    }
  }

  .sure-btn {
    width: 125px;
    height: 46px;
    cursor: pointer;
  }
}
</style>